.b-promo_banner {
	display: flex;
	justify-content: center;
	line-height: 0;
	margin: rh(5 0);
	opacity: 1;
	transition: opacity 0.3s ease;

	&-image {
		width: 100%;

		@include media(sm) {
			max-width: 500px;
		}

		img {
			border-radius: $global-radius;
			width: 100%;
		}
	}

	&:hover {
		opacity: 0.85;
	}

	&.m-with_shadow &-image {
		border-radius: $global-radius;
		box-shadow: $depth-1;
	}
}

.b-promo_box-picture {
	@include media(sm) {
		height: 100% !important; /* stylelint-disable-line */
		min-height: 420px;
	}
}

.b-promo_box-content.m-vertical-top {
	@include media(sm) {
		top: 0;
	}
}
