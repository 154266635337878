.cookie-modal {
	bottom: 0;
	display: none;
	left: 0;
	position: fixed;
	right: 0;
	text-align: center;
	transition: 0.5s bottom;
	z-index: 10;

	.cookie-modal-content {
		background-color: $color-brand;
		color: $color-white;
		padding: rh(4 23 4 4);

		@include media(sm) {
			padding: 20px 30px;
			text-align: center;
		}
	}

	.cookie-modal-close {
		background: $color-white;
		border: none;
		border-radius: rh(5);
		color: $color-black;
		margin-left: 2vw;
		padding: rh(2 4);

		@include media(sm) {
			margin: 5px;
		}
	}

	.cookie-modal-link {
		color: $color-white;
		text-decoration: underline;
	}

	&.js-show {
		display: block;
	}
}

.embeddedServiceHelpButton .helpButton { //stylelint-disable-line
	&.fixed-cookie {
		@include media(md-down) {
			bottom: 270px;
			right: 16px !important; //stylelint-disable-line
		}
	}
}

.embeddedServiceSidebar .helpButton { //stylelint-disable-line
	&.embeddedServiceSidebarMinimizedDefaultUI { //stylelint-disable-line
		@include media(md-down) {
			bottom: 270px !important; //stylelint-disable-line
		}
	}
}
