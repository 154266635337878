.b-banners_grid {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	margin-bottom: rh(14);

	@include media(md-down) {
		margin-bottom: 0;
	}

	&-item {
		line-height: 0;
		margin-bottom: rh(9);
		margin-right: rh(7);
		opacity: 1;
		text-align: center;
		transition: opacity 0.3s ease;
		width: calc(50% - 28px);

		@include media(md) {
			margin-right: rh(7);
		}

		@include media(sm) {
			margin: rh(0 0 5 0);
			width: 100%;
		}

		&:hover {
			opacity: 0.85;
		}

		&:nth-child(even) {
			margin-right: rh(0);

			@include media(sm) {
				margin: rh(0 0 5 0);
			}
		}
	}

	&-image {
		width: 100%;

		img {
			border-radius: $global-radius;
			width: 100%;
		}
	}
}
