$color-light-turquoise: $color-turquoise;
$color-french-rose: $color-pink;
$color-fruit-salad: $color-green;
$color-dark-tangerine: $color-orange;
$color-rich-blue: $color-purple;
$color-alizarin: $color-red;
$color-coral: $color-pink;
$color-denim: $color-blue;
$color-steel-blue: $color-blue51;
$color-yellow-orange: $color-yellow;
$colorsList: (
	'turquoise': $color-light-turquoise,
	'french-rose': $color-french-rose,
	'fruit-salad': $color-fruit-salad,
	'dark-tangerine': $color-dark-tangerine,
	'rich-blue': $color-rich-blue,
	'alizarin': $color-alizarin,
	'coral': $color-coral,
	'denim': $color-denim,
	'steel-blue': $color-blue51,
	'yellow-orange': $color-yellow
);

.b-age_categories {
	display: flex;
	justify-content: space-between;
	margin-bottom: rh(20);

	@include media(md-down) {
		margin-bottom: rh(6);
	}

	@include media(sm) {
		flex-wrap: wrap;
	}

	&-item {
		border: solid 2px rgba($color-blue, 0.12);
		border-radius: 5px;
		display: table;
		font-family: $font-serif;
		height: 160px;
		margin-right: rh(8);
		max-width: 160px;
		min-width: 90px;
		width: 100%;

		@include media(md) {
			height: auto;
			margin-right: rh(7);
			max-width: 90px;
			min-height: 90px;
		}

		@include media(sm) {
			height: auto;
			margin-bottom: rh(5);
			margin-right: rh(0);
			max-width: 100%;
		}

		&:last-child {
			margin-right: rh(0);
		}

		.b-theme_babies & {
			border-color: rgba($color-purple, 0.12);
		}

		.b-theme_hmv & {
			border-color: rgba($color-hmv-pink, 0.12);
		}

		.b-theme_rle & {
			letter-spacing: 0.03em;
		}

		&:hover {
			box-shadow: $depth-1;
		}
	}

	@each $name, $color-item in $colorsList {
		&-item.m-#{$name} &-link {
			color: $color-item;
		}

		&-item.m-#{$name}:hover {
			background-color: $color-item;
			border-color: $color-item;
		}
	}

	&-link {
		display: table-cell;
		height: 100%;
		text-align: center;
		text-decoration: none;
		vertical-align: middle;
		width: 100%;

		@include media(sm) {
			padding: rh(4);
		}

		&:hover {
			text-decoration: none;
		}
	}

	&-item:hover &-link {
		color: $color-white;
	}

	&-number {
		display: block;
		font-size: 48px;
		font-weight: 600;
		line-height: 0.75;
		margin-bottom: rh(1);

		@include media(md) {
			font-size: 30px;
		}

		@include media(sm) {
			display: inline-block;
			font-size: 36px;
			margin-bottom: rh(0);
			margin-right: rh(2);
			vertical-align: middle;
		}
	}

	&-text {
		font-size: 24px;

		@include media(md-down) {
			font-size: 18px;
		}

		@include media(sm) {
			vertical-align: middle;
		}

		&.m-big {
			font-size: 48px;
			font-weight: 600;

			@include media(md) {
				font-size: 30px;
			}

			@include media(sm) {
				font-size: 36px;
				line-height: 27px;
			}
		}
	}
}
