// Palette
/* stylelint-disable color-function-notation */
$color-black: hsl(0deg, 0%, 0%);
$color-dark_grey: hsl(214deg, 4%, 34%);
$color-grey: hsl(45deg, 2%, 47%);
$color-grey44: hsl(0deg, 0%, 44%); // dimmed text on white
$color-grey65: hsl(0deg, 0%, 65%);
$color-grey77: hsl(0deg, 0%, 77%);
$color-grey91: hsl(0deg, 0%, 91%);
$color-grey97: hsl(0deg, 0%, 97%);
$color-white: hsl(0deg, 0%, 100%);
$color-blue: hsl(218deg, 85%, 40%);
$color-blue51: hsl(212deg, 50%, 51%);
$color-blue52: hsl(218deg, 56%, 52%);
$color-blue65: hsl(211deg, 55%, 65%);
$color-red: hsl(340deg, 100%, 40%);
$color-teal: #00bfb8;
$color-teal-hmv: #00c4b3;
$color-teal-dark: #004f44;
$color-pink: hsl(333deg, 87%, 67%);
$color-hmv-pink: hsl(310deg, 100%, 43%);
$color-orange: hsl(26deg, 100%, 50%);
$color-yellow: hsl(38deg, 98%, 63%);
$color-green: hsl(94deg, 65%, 45%);
$color-purple: hsl(261deg, 28%, 42%);
$color-hmv-purple: #962ee2;
$color-turquoise: hsl(172deg, 72%, 55%);
$color-sand: hsl(53deg, 63%, 67%);
$color-narcissus: hsl(37deg, 50%, 53%);
$color-clover: hsl(75deg, 11%, 36%);
$color-faded_rose: hsl(0deg, 42%, 57%);
/* stylelint-enable color-function-notation */
$color-rooms: #035a4f;
$color-background-variation-cart: #f3f6fd;
$color-background-variation-product-modal: #efefef;

// Applied colors
$color-brand-raw: var(--color-brand-raw);
$color-brand: var(--color-brand);
$color-brand52: var(--color-brand52);
$color-brand64: var(--color-brand64);
$color-brand76: var(--color-brand76);
$color-brand88: var(--color-brand88);
$color-brand94: var(--color-brand94);
$color-brand97: var(--color-brand97);
$color-link: var(--color-link);
$color-text: $color-black;
$color-divider: $color-brand88;
$color-disabled: $color-brand88;
$color-web: var(--color-web);

// Fonts
$font-sans: var(--font-sans), 'Futura', 'Tw Cen MT', 'FreeSans', sans-serif; // geometric
$font-serif: var(--font-serif), 'Georgia', 'DejaVu Serif', serif; // slab-serif
$font-mono: var(--font-mono), 'SFMono-Regular', 'Menlo', 'Consolas', 'Lucida Console', monospace;

// Sizes
$size-font: 1rem; // 16px
$size-line: $size-font * 1.25; // 20px

// Breakpoints
$break-xl: 1201px;
$break-lg: 1200px;
$break-md: 768px;
$break-sm: 400px;

// Z-indexes
$z-indexes: (
	components: (
		footer-email: (),
		select: (),
		radio-box: (
			decoration: (),
			content: ()
		),
		checkbox: (
			before: (),
			after: (),
			icon: (),
		),
		slick-slider: (
			arrows: (),
			dots: (),
			content: (),
		),
		main-menu: (
			flyout-pane: (),
			flyout-content: (),
		),
		main-menu-hamburger: (
			flyout-visible: (),
		),
		header-search: (
			suggestions: (),
			combobox: (),
			outline: (),
			input: (),
			buttons: (),
		)
	),
	blocking-busy-overlay: (),
	spinner: (),
	content: (),
	search-flyout-pane: (),
	search-visible-elements: (),
	search: (),
	popup-menu: (),
	overlay-partial: (),
	header: (),
	overlay: (),
	modal: (),
	minicart: (),
	skip-content: (),
	notifier: (),
	highlighter: ()
);

// Z-depth
$depth-0: none;
$depth-1: var(--depth-1);
$depth-2: var(--depth-2);
$depth-3: var(--depth-3);
$global-box-shadow: deprecation('$global-box-shadow', $depth-1); // stylelint-disable-line

// Motion
$motion-ease: ease 0.3s;
$motion-ease-panels: ease 0.5s;

// Globals
$global-radius: 5px;
$max-content-width: 1200px;
