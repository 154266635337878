.b-featured_item {
	margin: rh(0 5);
	max-width: 256px;

	@include media(md) {
		margin: rh(0 4);
	}

	@include media(sm) {
		margin-bottom: rh(8);
		width: 256px;
	}

	&-link {
		color: $color-text;
		display: block;
		height: 100%;
		text-decoration: none;

		&:hover {
			text-decoration: underline;
		}
	}

	&-image {
		align-items: center;
		display: flex;
		height: 216px;
		justify-content: center;
		margin-bottom: rh(3);
		text-align: center;

		@include media(md) {
			height: 150px;
		}

		img {
			max-height: 100%;
			object-fit: contain;
			width: 100%;
		}
	}

	&-title {
		color: $color-text;
		font-family: $font-sans;
		font-size: 16px;
		line-height: 20px;
		margin-top: rh(3);
		padding: rh(0 4);
		text-align: center;
	}

	&:first-child {
		margin-left: rh(0);

		@include media(sm) {
			margin-left: rh(3);
		}
	}

	&:last-child {
		margin-right: rh(0);

		@include media(sm) {
			margin-left: rh(3);
		}
	}
}
