$media: (
	sm: 'screen and (max-width: #{$break-md - 1})',
	md: 'screen and (min-width: #{$break-md}) and (max-width: #{$break-lg - 1})',
	lg: 'screen and (min-width: #{$break-lg}) and (max-width: #{$break-xl - 1})',
	xl: 'screen and (min-width: #{$break-xl})',
	md-up: 'screen and (min-width: #{$break-md})',
	md-down: 'screen and (max-width: #{$break-lg - 1})',
	lg-up: 'screen and (min-width: #{$break-lg})',
	tablet-landscape: 'screen and (min-width: #{$break-md}) and (max-width: #{$break-lg - 1}) and (orientation: landscape)',
	tablet-pro: 'screen and (min-width: 1024px) and (max-height: 1366px) and (-webkit-min-device-pixel-ratio: 1.5)',
	iphone-plus: 'screen and (max-width: 414px)',
	iphone: 'screen and (max-width: 375px)',
	phone-xs: 'screen and (max-width: 365px)',
	iphone-5: 'screen and (max-width: 320px)',
);

/*md
@no-stat

# Media queries (breakpoints)

We have a `media()` mixin for make it easier to implement responsive styling via media queries.

## Configuration

`media` mixin works with `$media` map where there're key-value pairs:
`media-name: media query`

This is how `$media` map looks:

```
$media: (
	sm: 'screen and (max-width: 767px)',
	md: 'screen and (min-width: 768px) and (max-width: 1199px)',
	lg: 'screen and (min-width: 1200px)',
	xl: 'screen and (min-width: 1201px)',
);
```

[Please see](https://confluence.ontrq.com/display/TOYSR/TOYSR+Site+Breakpoints+Conception)

## Usage

Here is how to use `media()` mixin:

```scss
.b-block {
	// styles outside of a media query

	@include media(sm) {
		// styles for "s" viewports
	};

	@include media(md-up) {
		// styles for "m" and "l" viewports
	};
}
```

Simply edit this file and add your own media queries to `$media` map.

*/

// stylelint-disable
@mixin media($query) {
	$__query: null;

	@each $type in $query {
		@if map-has-key($media, $type) {
			$__query: append($__query, unquote(map-get($media, $type)), comma);
		} @else {
			@warn $type;
		}
	}

	@media #{$__query} {
		@content;
	}
}

@mixin h-visible {
	display: block;

	@each $brp, $val in $media {
		&-#{$brp} {
			display: none;

			@include media($brp) {
				display: block;
			}
		}
	}
}

.h-visible {
	@include h-visible;
}
