.b-carousel_slide {
	&-content {
		padding: 5% 10%;
		position: absolute;
		text-align: center;
		top: 50%;
		transform: translateY(-50%);
		width: 100%;
		z-index: z(components, slick-slider, content);
	}

	&-title,
	&-subtitle {
		color: $color-white;
		text-align: center;
		text-transform: uppercase;
	}

	&-title {
		font-family: $font-sans;
		font-size: 72px;
		font-style: oblique;
		margin-bottom: rh(8);
		margin-top: rh(3);

		@include media(md) {
			font-size: 60px;
		}

		@include media(sm) {
			font-size: 36px;
			line-height: 1;
		}
	}

	&-subtitle {
		font-family: $font-serif;
		font-size: 30px;

		@include media(md) {
			font-size: 24px;
		}

		@include media(sm) {
			font-size: 16px;
		}

		.b-theme_rle & {
			letter-spacing: 0.03em;
		}
	}
}
