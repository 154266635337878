.b-product_list {
	display: flex;
	justify-content: flex-start;
	margin: rh(3 0);

	@include media(sm) {
		flex-wrap: wrap;
		justify-content: center;
	}

	&.m-homepage {
		margin-bottom: rh(23);

		@include media(md-down) {
			margin-bottom: rh(3);
		}
	}
}
