$color-arrows: url-color-legacy($color-blue52);
$color-arrows-hover: url-color-legacy($color-blue);
$color-arrows-babies: url-color-legacy($color-purple);
$color-arrows-rle: url-color-legacy($color-grey);
$color-arrows-hmv: url-color($color-hmv-pink);

.b-hero_carousel {
	@include g-carousel;

	background: $color-brand97;
	height: 440px;
	margin: rh(9 0 23 0);
	position: relative;

	@include media(md) {
		height: 300px;
		margin: rh(6 -10);
	}

	@include media(sm) {
		height: 280px;
		margin: rh(5 -4);
	}

	&-slide {
		@include g-carousel(_slide);
	}

	&-image {
		width: 100%;

		img {
			border-radius: $global-radius;
			height: 440px;
			margin: 0 auto;
			object-fit: cover;
			position: relative;
			width: 100%;

			@include media(md) {
				border-radius: 0;
				height: 300px;
			}

			@include media(sm) {
				border-radius: 0;
				height: 280px;
			}
		}
	}

	.slick-slide {
		position: relative;
	}

	.slick-dots {
		bottom: 25px;
		left: 50%;
		position: absolute;
		text-align: center;
		transform: translateX(-50%);
		z-index: z(components, slick-slider, dots);

		@include media(sm) {
			bottom: 10px;
		}

		li {
			display: inline-block;
			font-size: 0;
			margin-right: rh(3.5);

			&:last-child {
				margin-right: 0;
			}
		}

		button {
			background-color: $color-white;
			border: 1px solid $color-brand76;
			border-radius: 50%;
			box-shadow: $depth-1;
			cursor: pointer;
			font-size: 0;
			height: 10px;
			padding: rh(0);
			transition: background-color 0.3s ease;
			width: 10px;

			@include media(sm) {
				height: 8px;
				width: 8px;
			}

			&:hover {
				border-color: $color-brand;
			}
		}

		.slick-active button {
			background-color: $color-brand;
			border: 1px solid $color-white;
			height: 12px;
			width: 12px;

			@include media(sm) {
				height: 10px;
				width: 10px;
			}
		}
	}

	.slick-arrow {
		background: none;
		border: 0;
		border-radius: $global-radius;
		box-shadow: $depth-1;
		cursor: pointer;
		font-size: 0;
		height: 50px;
		padding: 0;
		position: absolute;
		top: 50%;
		transition: all 0.3s ease;
		transition-property: border, background-color;
		width: 50px;
		z-index: z(components, slick-slider, arrows);

		@include media(sm) {
			height: 40px;
			width: 30px;
		}

		&::after {
			background-color: $color-white;
			border: solid 1px $color-brand94;
			border-radius: $global-radius;
			content: '';
			height: 100%;
			left: 0;
			opacity: 0.9;
			position: absolute;
			top: 0;
			transition: all 0.3s ease;
			transition-property: border, background-color, opacity;
			width: 100%;
			z-index: -1;
		}

		&:hover {
			border-color: $color-brand;
			opacity: 1;

			&::after {
				border-color: $color-brand;
				opacity: 1;
			}
		}

		&.slick-prev,
		&.slick-next {
			&::before {
				content: '';
				display: inline-block;
				height: 21px;
				width: 12px;

				@include media(sm) {
					background-size: 8px;
					height: 14px;
					padding-right: rh(1);
					width: 8px;
				}
			}
		}

		&.slick-prev {
			left: 0;
			transform: translate(-50%, -50%);

			@include media(md-down) { // stylelint-disable-line
				transform: translate(0, -50%);

				&:active {
					transform: translate(0, -50%) scale(0.97);
				}
			}

			@include media(sm) {
				border-bottom-left-radius: 0;
				border-top-left-radius: 0;
			}

			&:active {
				transform: translate(-50%, -50%) scale(0.97);
			}

			&::after {
				@include media(sm) {
					border-bottom-left-radius: 0;
					border-top-left-radius: 0;
				}
			}

			&::before {
				background-image: url('data:image/svg+xml;charset=utf8,\%3Csvg%20xmlns%3D%27http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%27%20fill%3D%27#{$color-arrows}%27%20width%3D%2712px%27%20height%3D%2721px%27%3E%3Cpath%20d%3D%27M.429%208.997L9.465.384a1.523%201.523%200%200%201%202.081%200%201.357%201.357%200%200%201%200%201.984L3.552%209.989l7.994%207.621a1.358%201.358%200%200%201%200%201.984%201.523%201.523%200%200%201-2.081%200L.429%2010.98a1.366%201.366%200%200%201-.43-.991c0-.359.144-.718.43-.992z%27%2F%3E%3C%2Fsvg%3E');
				transition: all 0.3s ease;
				transition-property: background-image;

				.b-theme_babies & {
					background-image: url('data:image/svg+xml;charset=utf8,\%3Csvg%20xmlns%3D%27http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%27%20fill%3D%27#{$color-arrows-babies}%27%20width%3D%2712px%27%20height%3D%2721px%27%3E%3Cpath%20d%3D%27M.429%208.997L9.465.384a1.523%201.523%200%200%201%202.081%200%201.357%201.357%200%200%201%200%201.984L3.552%209.989l7.994%207.621a1.358%201.358%200%200%201%200%201.984%201.523%201.523%200%200%201-2.081%200L.429%2010.98a1.366%201.366%200%200%201-.43-.991c0-.359.144-.718.43-.992z%27%2F%3E%3C%2Fsvg%3E');
				}

				.b-theme_hmv & {
					background-image: url('data:image/svg+xml;charset=utf8,\%3Csvg%20xmlns%3D%27http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%27%20fill%3D%27#{$color-arrows-hmv}%27%20width%3D%2712px%27%20height%3D%2721px%27%3E%3Cpath%20d%3D%27M.429%208.997L9.465.384a1.523%201.523%200%200%201%202.081%200%201.357%201.357%200%200%201%200%201.984L3.552%209.989l7.994%207.621a1.358%201.358%200%200%201%200%201.984%201.523%201.523%200%200%201-2.081%200L.429%2010.98a1.366%201.366%200%200%201-.43-.991c0-.359.144-.718.43-.992z%27%2F%3E%3C%2Fsvg%3E');
				}

				.b-theme_rle & {
					background-image: url('data:image/svg+xml;charset=utf8,\%3Csvg%20xmlns%3D%27http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%27%20fill%3D%27#{$color-arrows-rle}%27%20width%3D%2712px%27%20height%3D%2721px%27%3E%3Cpath%20d%3D%27M.429%208.997L9.465.384a1.523%201.523%200%200%201%202.081%200%201.357%201.357%200%200%201%200%201.984L3.552%209.989l7.994%207.621a1.358%201.358%200%200%201%200%201.984%201.523%201.523%200%200%201-2.081%200L.429%2010.98a1.366%201.366%200%200%201-.43-.991c0-.359.144-.718.43-.992z%27%2F%3E%3C%2Fsvg%3E');
				}
			}

			&:hover {
				&::before {
					background-image: url('data:image/svg+xml;charset=utf8,\%3Csvg%20xmlns%3D%27http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%27%20fill%3D%27#{$color-arrows-hover}%27%20width%3D%2712px%27%20height%3D%2721px%27%3E%3Cpath%20d%3D%27M.429%208.997L9.465.384a1.523%201.523%200%200%201%202.081%200%201.357%201.357%200%200%201%200%201.984L3.552%209.989l7.994%207.621a1.358%201.358%200%200%201%200%201.984%201.523%201.523%200%200%201-2.081%200L.429%2010.98a1.366%201.366%200%200%201-.43-.991c0-.359.144-.718.43-.992z%27%2F%3E%3C%2Fsvg%3E');

					.b-theme_babies & {
						background-image: url('data:image/svg+xml;charset=utf8,\%3Csvg%20xmlns%3D%27http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%27%20fill%3D%27#{$color-arrows-babies}%27%20width%3D%2712px%27%20height%3D%2721px%27%3E%3Cpath%20d%3D%27M.429%208.997L9.465.384a1.523%201.523%200%200%201%202.081%200%201.357%201.357%200%200%201%200%201.984L3.552%209.989l7.994%207.621a1.358%201.358%200%200%201%200%201.984%201.523%201.523%200%200%201-2.081%200L.429%2010.98a1.366%201.366%200%200%201-.43-.991c0-.359.144-.718.43-.992z%27%2F%3E%3C%2Fsvg%3E');
					}

					.b-theme_hmv & {
						background-image: url('data:image/svg+xml;charset=utf8,\%3Csvg%20xmlns%3D%27http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%27%20fill%3D%27#{$color-arrows-hmv}%27%20width%3D%2712px%27%20height%3D%2721px%27%3E%3Cpath%20d%3D%27M.429%208.997L9.465.384a1.523%201.523%200%200%201%202.081%200%201.357%201.357%200%200%201%200%201.984L3.552%209.989l7.994%207.621a1.358%201.358%200%200%201%200%201.984%201.523%201.523%200%200%201-2.081%200L.429%2010.98a1.366%201.366%200%200%201-.43-.991c0-.359.144-.718.43-.992z%27%2F%3E%3C%2Fsvg%3E');
					}

					.b-theme_rle & {
						background-image: url('data:image/svg+xml;charset=utf8,\%3Csvg%20xmlns%3D%27http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%27%20fill%3D%27#{$color-arrows-rle}%27%20width%3D%2712px%27%20height%3D%2721px%27%3E%3Cpath%20d%3D%27M.429%208.997L9.465.384a1.523%201.523%200%200%201%202.081%200%201.357%201.357%200%200%201%200%201.984L3.552%209.989l7.994%207.621a1.358%201.358%200%200%201%200%201.984%201.523%201.523%200%200%201-2.081%200L.429%2010.98a1.366%201.366%200%200%201-.43-.991c0-.359.144-.718.43-.992z%27%2F%3E%3C%2Fsvg%3E');
					}
				}
			}
		}

		&.slick-next {
			right: 0;
			transform: translate(50%, -50%);

			@include media(md-down) { // stylelint-disable-line
				transform: translate(0, -50%);

				&:active {
					transform: translate(0, -50%) scale(0.97);
				}
			}

			&:active {
				transform: translate(50%, -50%) scale(0.97);
			}

			&::after {
				@include media(sm) {
					border-bottom-right-radius: 0;
					border-top-right-radius: 0;
				}
			}

			&::before {
				background-image: url('data:image/svg+xml;charset=utf8,\%3Csvg%20xmlns%3D%27http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%27%20fill%3D%27#{$color-arrows}%27%20width%3D%2712px%27%20height%3D%2721px%27%3E%3Cpath%20d%3D%27M11.568%2011.001l-9.035%208.614a1.525%201.525%200%200%201-2.081%200%201.357%201.357%200%200%201%200-1.984l7.994-7.621L.452%202.388a1.357%201.357%200%200%201%200-1.983%201.523%201.523%200%200%201%202.081%200l9.035%208.613c.287.274.431.632.431.992%200%20.358-.144.718-.431.991z%27%2F%3E%3C%2Fsvg%3E');
				transition: all 0.3s ease;
				transition-property: background-image;

				.b-theme_babies & {
					background-image: url('data:image/svg+xml;charset=utf8,\%3Csvg%20xmlns%3D%27http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%27%20fill%3D%27#{$color-arrows-babies}%27%20width%3D%2712px%27%20height%3D%2721px%27%3E%3Cpath%20d%3D%27M11.568%2011.001l-9.035%208.614a1.525%201.525%200%200%201-2.081%200%201.357%201.357%200%200%201%200-1.984l7.994-7.621L.452%202.388a1.357%201.357%200%200%201%200-1.983%201.523%201.523%200%200%201%202.081%200l9.035%208.613c.287.274.431.632.431.992%200%20.358-.144.718-.431.991z%27%2F%3E%3C%2Fsvg%3E');
				}

				.b-theme_hmv & {
					background-image: url('data:image/svg+xml;charset=utf8,\%3Csvg%20xmlns%3D%27http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%27%20fill%3D%27#{$color-arrows-hmv}%27%20width%3D%2712px%27%20height%3D%2721px%27%3E%3Cpath%20d%3D%27M11.568%2011.001l-9.035%208.614a1.525%201.525%200%200%201-2.081%200%201.357%201.357%200%200%201%200-1.984l7.994-7.621L.452%202.388a1.357%201.357%200%200%201%200-1.983%201.523%201.523%200%200%201%202.081%200l9.035%208.613c.287.274.431.632.431.992%200%20.358-.144.718-.431.991z%27%2F%3E%3C%2Fsvg%3E');
				}

				.b-theme_rle & {
					background-image: url('data:image/svg+xml;charset=utf8,\%3Csvg%20xmlns%3D%27http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%27%20fill%3D%27#{$color-arrows-rle}%27%20width%3D%2712px%27%20height%3D%2721px%27%3E%3Cpath%20d%3D%27M11.568%2011.001l-9.035%208.614a1.525%201.525%200%200%201-2.081%200%201.357%201.357%200%200%201%200-1.984l7.994-7.621L.452%202.388a1.357%201.357%200%200%201%200-1.983%201.523%201.523%200%200%201%202.081%200l9.035%208.613c.287.274.431.632.431.992%200%20.358-.144.718-.431.991z%27%2F%3E%3C%2Fsvg%3E');
				}
			}

			&:hover {
				&::before {
					background-image: url('data:image/svg+xml;charset=utf8,\%3Csvg%20xmlns%3D%27http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%27%20fill%3D%27#{$color-arrows-hover}%27%20width%3D%2712px%27%20height%3D%2721px%27%3E%3Cpath%20d%3D%27M11.568%2011.001l-9.035%208.614a1.525%201.525%200%200%201-2.081%200%201.357%201.357%200%200%201%200-1.984l7.994-7.621L.452%202.388a1.357%201.357%200%200%201%200-1.983%201.523%201.523%200%200%201%202.081%200l9.035%208.613c.287.274.431.632.431.992%200%20.358-.144.718-.431.991z%27%2F%3E%3C%2Fsvg%3E');

					.b-theme_babies & {
						background-image: url('data:image/svg+xml;charset=utf8,\%3Csvg%20xmlns%3D%27http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%27%20fill%3D%27#{$color-arrows-babies}%27%20width%3D%2712px%27%20height%3D%2721px%27%3E%3Cpath%20d%3D%27M11.568%2011.001l-9.035%208.614a1.525%201.525%200%200%201-2.081%200%201.357%201.357%200%200%201%200-1.984l7.994-7.621L.452%202.388a1.357%201.357%200%200%201%200-1.983%201.523%201.523%200%200%201%202.081%200l9.035%208.613c.287.274.431.632.431.992%200%20.358-.144.718-.431.991z%27%2F%3E%3C%2Fsvg%3E');
					}

					.b-theme_hmv & {
						background-image: url('data:image/svg+xml;charset=utf8,\%3Csvg%20xmlns%3D%27http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%27%20fill%3D%27#{$color-arrows-hmv}%27%20width%3D%2712px%27%20height%3D%2721px%27%3E%3Cpath%20d%3D%27M11.568%2011.001l-9.035%208.614a1.525%201.525%200%200%201-2.081%200%201.357%201.357%200%200%201%200-1.984l7.994-7.621L.452%202.388a1.357%201.357%200%200%201%200-1.983%201.523%201.523%200%200%201%202.081%200l9.035%208.613c.287.274.431.632.431.992%200%20.358-.144.718-.431.991z%27%2F%3E%3C%2Fsvg%3E');
					}

					.b-theme_rle & {
						background-image: url('data:image/svg+xml;charset=utf8,\%3Csvg%20xmlns%3D%27http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%27%20fill%3D%27#{$color-arrows-rle}%27%20width%3D%2712px%27%20height%3D%2721px%27%3E%3Cpath%20d%3D%27M11.568%2011.001l-9.035%208.614a1.525%201.525%200%200%201-2.081%200%201.357%201.357%200%200%201%200-1.984l7.994-7.621L.452%202.388a1.357%201.357%200%200%201%200-1.983%201.523%201.523%200%200%201%202.081%200l9.035%208.613c.287.274.431.632.431.992%200%20.358-.144.718-.431.991z%27%2F%3E%3C%2Fsvg%3E');
					}
				}
			}
		}
	}
}
