$sectionTitleMods: (
	'm-blue': $color-brand,
	'm-red': $color-red,
	'm-orange': $color-orange,
	'm-green': $color-green,
	'm-coral': $color-pink,
	'm-steel-blue': $color-blue51,
	'm-brand': $color-brand
);

.b-section_title {
	background-color: $color-brand97;
	border-radius: $global-radius;
	margin: rh(8 0);
	padding: rh(3 0 2 15);
	position: relative;

	@include media(sm) {
		margin: rh(8 -4);
		padding: rh(3 5 3 21);
	}

	&-text {
		font-family: $font-sans;
		font-size: 18px;
		font-weight: 600;
		text-transform: uppercase;

		.b-theme_rle & {
			font-weight: 500;
		}
	}

	&-text_mobile {
		display: none;

		.b-cart & {
			@include media(sm) {
				display: block;
			}
		}
	}

	&-logo {
		left: -10px;
		position: absolute;
		top: 50%;
		transform: translateY(-50%);

		@include media(sm) {
			left: 15px;
			transform: translateY(-50%) scale(0.9);
		}
	}

	@each $name, $color-item in $sectionTitleMods {
		&.#{$name} &-text {
			color: $color-item;
		}

		&.#{$name} &-logo path {
			fill: $color-item;
		}
	}

	&.m-clp {
		margin-left: rh(2);
	}

	&-noresults {
		display: none;

		.m-no_results & {
			display: block;
		}
	}
}
