@mixin g-carousel(
	$_: root,
	$_height: 600px
) {
	@if $_ == root {
		font-size: 0;
		overflow-x: auto;
		overflow-y: hidden;
		text-align: center;
		white-space: nowrap;
	}

	@if $_ == _slide {
		display: inline-block;
		font-size: $size-font;
		vertical-align: top;
		white-space: normal;
	}

	@if $_ == -vertical {
		max-height: $_height;
		overflow-y: auto;
	}

	&.slick-initialized {
		overflow-x: visible;
		overflow-y: visible;
		white-space: normal;
		height: auto;
	}
}
