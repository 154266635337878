.b-banners_group {
	margin: rh(10) 0 rh(23) 0;

	@include media(md-down) {
		margin-bottom: rh(10);
	}

	.b-promo_banner {
		margin-bottom: rh(15);

		@include media(md-down) {
			margin-bottom: rh(5);
		}
	}
}
